import React, { useState, useEffect } from 'react';
import styled, {createGlobalStyle} from 'styled-components';

const SocialBar = styled.div`
  background-color: #070022;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
`;

const SocialInfo = styled.div`
  display: flex;
  align-items: center;
`;

const DragonForged = styled.h2`
  margin-bottom: 10px;
  margin-left: 10px; /* Add left margin to create space between elements */
  font-family: 'Dragonforged', sans-serif;
  background: linear-gradient(180deg, orange, yellow);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const Games = styled.h2`
  margin-bottom: 10px;
  margin-left: 5px; /* Add left margin to create space between elements */
  font-family: 'Dragonforged', sans-serif;
`;

const SocialButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SocialButton = styled.a`
  color: #ffffff;
  margin-right: 20px;
  text-decoration: none;
`;

const NavBar = () => {
    return(
    <SocialBar>
        <LogoContainer>
          <Logo src="logo.png" alt="Dragonforged Games" />
          <DragonForged>Dragonforged</DragonForged>
          <Games>Games</Games>
        </LogoContainer>
        <SocialButtonContainer>
          <SocialButton href="https://twitter.com/dragonforgedllc" target="_blank" rel="noopener noreferrer">
            Twitter
          </SocialButton>
        </SocialButtonContainer>
    </SocialBar>
    );
}

export default NavBar;