import React, { useState, useEffect } from 'react';
import styled, {createGlobalStyle} from 'styled-components';

const RouletteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh); /* Subtract the height of the SocialBar from the viewport height */
  position: relative;
  overflow: hidden;
  z-index: 0;
`;

const RouletteWrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  transition: transform 1s;
`;

const RouletteImage = styled.img`
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, black 0%, #111111 45%, transparent 95%, transparent 100%);
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 20px;
  color: #fff;
`;

const Overlay2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, black 0%, transparent 80%, transparent 100%);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 30%;
  padding: 10%;
`;


const GameTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 10px;
  font-family: 'Dragonforged', sans-serif;
  background: linear-gradient(180deg, orange, yellow);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const GameDescription = styled.p`
  font-size: 20px;
  margin-bottom: 25px;
  font-family: "Tahoma", sans-serif
`;

const FindOutMoreButton = styled.a`
  background-color: #fff;
  color: #070022;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

const CircleIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? '#ffffff' : '#cccccc')};
  margin: 0 5px;
`;

const Roulette = ({ games }) => {
  const [currentGame, setCurrentGame] = useState(0);
  const [nextGame, setNextGame] = useState(1);

  useEffect(() => {
    if (games && games.length > 0) {
      const interval = setInterval(() => {
        setCurrentGame(nextGame);
        setNextGame((nextGame + 1 == games.length ? 0 : nextGame + 1));
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [games, nextGame]);

  if (!games || games.length === 0) {
    return null;
  }

  return (
    <RouletteContainer>
      <RouletteWrapper
        style={{
          transform: `translateX(${-(currentGame * 100)}%)`,
        }}
      >
        {games.map((game, index) => (
          <RouletteImage
            key={game.title}
            src={game.image}
            alt={game.title}
            isActive={index === currentGame}
          />
        ))}
      </RouletteWrapper>
      <Overlay>
      <Overlay2>
        <ContentWrapper>
          <GameTitle>{games[currentGame].title}</GameTitle>
          <GameDescription>{games[currentGame].description}</GameDescription>
          {/*<FindOutMoreButton href={games[currentGame].link} target="_blank" rel="noopener noreferrer">
            Find Out More
          </FindOutMoreButton>*/}
        </ContentWrapper>
        </Overlay2>
      </Overlay>
      
    </RouletteContainer>
  );
};

export default Roulette;