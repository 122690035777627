import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Roulette from '../Components/Roulette';
import NavBar from '../Components/Header';

const GlobalStyles = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  background-color: #02000a;
  color: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -10;
`;

const Footer = styled.footer`
  background-color: #070022;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  width: 100%;
  bottom:0;
  position:absolute;
`;

const Title = styled.h1`
  font-family: 'Dragonforged', sans-serif;
  background: linear-gradient(180deg, orange, yellow);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 2.5em;
`;

const P = styled.p`
color: white;
font-size: 1.5em;
`;

const games = [
    {
      title: 'Golferz',
      description: "A casual golf experience for everyone! Compete for the best score in ETH Open, challenge your friends to a game of battle golf, or experience the fun of golf nft projects in mini golf!",
      image: 'Golferz.png',
      link: "https://golferz.club"
    },
    /*{
      title: 'Cyberspace.computer',
      description: "A cryptic adventure across the canto blockchain! Solve puzzles, ciphers, and clues to find the wearabouts of n00b_cypher, who disappeared after looking into the mystery of the identity of Satoshi Nakamoto",
      image: 'cyberspace.jpg',
      link: "https://cyberspace.computer"
    },*/
    {
        title: "Untitled Canto Game (1)",
        description: "Create, mint, and share your own platforming levels on the canto blockchain! Mint your character for free and build your level. Beat your level and mint it to the blockchain for everyone to play!",
        image: "UCG.png"
    },
    {
      title: "CantoCanvas",
      description: "Contribute to an onchain art project where wallets can add 1 pixel to the canvas every 30 minutes. Anyone can mint and keep the current canvas state forever as an NFT!",
      image: "Canvas.png"
  }
  ];

  const LandingPage = () => {
    return (
      <>
        <GlobalStyles />
        <Container>
          <NavBar />

          <Roulette games={games} />

          <Footer>(C) Dragonforged Games, LLC, 2023</Footer>
          
        </Container>
      </>
    );
  };

export default LandingPage;